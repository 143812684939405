.primary-button {
  position: relative;
  display: inline-block;
  padding: 0px 30px;
  background: #83ba09;
  color: #FFF;
  font-weight: 400;
  height: 45px;
  line-height: 45px;
  border: none;
  border-radius: 25px;
  overflow: hidden;
  opacity: 0.9;
  text-decoration: none;
  -webkit-transition: 0.6s all;
  transition: 0.6s all;
}


.primary-button:after {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 120%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: scale3d(6, 2, 1) rotate(135deg) translate(0, -150%);
  transform: scale3d(6, 2, 1) rotate(135deg) translate(0, -150%);
  -webkit-transition: 0.6s all;
  transition: 0.6s all;
}

.primary-button:hover:after, .primary-button:focus:after {
  -webkit-transform: scale3d(6, 2, 1) rotate(135deg) translate(0, 100%);
  transform: scale3d(6, 2, 1) rotate(135deg) translate(0, 100%);
}

.primary-button:hover, .primary-button:focus {
  color: #FFF;
  opacity: 1;
}
