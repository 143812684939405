.carousel-caption {
    bottom: 40%; /* Bu değeri ayarlayarak başlık ve paragrafın tam olarak nerede konumlandırılacağını belirleyebilirsiniz. */
    transform: translateY(50%); /* Bu, metni tam olarak ortada konumlandırmak için kullanılır. */
}

.carousel-caption h3 {
    font-size: 2.5em; /* Başlık boyutunu büyütmek için. Bu değeri istediğiniz boyuta göre ayarlayabilirsiniz. */
    margin-bottom: 15px; /* Başlık ile paragraf arasındaki boşluğu ayarlar. */
}

.carousel-caption p {
    font-size: 1.5em; /* Paragraf metni boyutunu büyütmek için. Bu değeri istediğiniz boyuta göre ayarlayabilirsiniz. */
}
.carousel-image-container {
    width: 100%;
    height: 100vh; /* Ekranın tam yüksekliğini alacak */
    overflow: hidden; 
}

.carousel-image {
    width: 100%; /* Genişlik %100 */
    height: 100%; /* Yükseklik %100 */
}