/* .carousel-item img {
    max-width: 500px;
    max-height: 300px;

    width: 100%;
    height: 100%;
    object-fit: contain;

    margin: auto;
    display: block;

    background-color: white;
}
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
}

.carousel-control-prev-icon {
    background-color: rgba(0, 0, 0, 0.5);
} */

.post-image-to-slider .carousel-item img {
    max-width: 500px;
    max-height: 300px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
    display: block;
    background-color: white;
}

.post-image-to-slider .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
}

.post-image-to-slider .carousel-control-prev-icon {
    background-color: rgba(0, 0, 0, 0.5);
}
