.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.image-modal img {
    max-width: 90%;
    max-height: 90%;
}

.image-modal .close, 
.image-modal .previous, 
.image-modal .next {
    position: absolute;
    color: white;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.image-modal .close {
    top: 10px;
    right: 10px;
}

.image-modal .previous {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.image-modal .next {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
