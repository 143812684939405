.nav-right-align {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.nav-right-align .nav-link:hover {
    color: #ff5733; /* örnek renk kodu, istediğiniz renkle değiştirebilirsiniz */
}
/* Navbar'daki linklerin metin boyutunu büyütelim */
.nav-right-align .nav-link {
    font-size: 20px; /* İstediğiniz boyuta göre bu değeri ayarlayabilirsiniz */
    padding: 15px 20px; /* Hem yatay hem de dikey boşlukları ayarlayarak öğeler arasında daha fazla boşluk oluşturabilirsiniz */
}

/* Eğer logo'nun boyutunu da büyütmek isterseniz: */
.navbar-brand img {
    width: 150px; /* İstediğiniz boyuta göre bu değeri ayarlayabilirsiniz */
    height: auto; /* Resmin orantılı kalmasını sağlamak için */
}

.bg-body-tertiary {
    padding: 0.25rem 1rem !important;
}

.bg-body-tertiary img {
    height: 3rem;  
    width: auto;
}

.nav-right-align .nav-link {
    font-size: 0.99rem;
    padding: 0.20rem 0.7rem;
}
